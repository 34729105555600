#header {
  height: 50px;
  background-color: #0e2441;
  font-size: 20px;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

#header a {
  color: white;  
  text-decoration: none;
}

#right-header {
  margin-right: 20px;
  margin-left: auto;
  font-size: 15px;
}

.posts-container {
  display: flex;
  flex-direction: column;
}

.post-box {
  border: solid black;
  border-width: 1px;
  width: 50%;
  padding: 50px;
  margin: 25px auto;
}

.post-title {
  font-size: 35px;
}

#form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: auto;
}

#input-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.form-group > textarea {
  resize: vertical;
}